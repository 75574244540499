
.containr {
  height: 500px;
  width: 450px;
  box-shadow:  20px 20px 40px rgba(0, 0, 0, 0.81);
  background-size: cover;
  font-family: 'Abel', sans-serif;
  background-image: url("https://xp.io/storage/15pewlWA.jpg");

}
.post{
  font-size: 25px;
  width:280px;
}

.content {
  bottom: -5vh;
  position: absolute;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  color: white;
  margin-bottom: -30px;
  padding: 20px;
  text-shadow: 4px 4px 4px #000000;
}

.Name {
  position:relative;
  font-family: Montserrat;
  color: white;
  font-weight: 500;
  text-align: left;
  font-size: 35px;
}

.details {
  position:relative;
  text-align: left;
  width:330px;
  bottom:50px;
  font-style: italic;
  color: rgb(185, 206, 224);
}

.icons {
  display: flex;
  width:400px;
  justify-content: space-between;
}

.icons .icon {
  font-size: 25px;
  size:40px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  transition: 0.3s;
}

@media screen and(min-width:200px) {
  .container {
      height: 350px;
      width: 200px;
  }
  .Name {
      font-size: x-large;
  }
  .icons i {
      font-size: large;
      margin-bottom: 10px;
  }
}

@media screen and (max-width:420px) {
    .swiper {
      width:327px;
      height:auto;
    }
    .product-img{
        float:center;
        width:250px;
        height:auto;
    }
    .img{
        height:200px;
        transform: translateY(5px);
    }
    .product-info{
        height:380px;
    }
    .containr {
      height: 360px;
      width: 300px;
    }.content {
      bottom: 30px;
      margin-bottom: 0.2vh;
      padding: 10px;
    }
    .icons{
      width: 70vw;
    }
    .Name{
      font-size: 30px;
    }
    .post{
      width: 200px;
    }
}
