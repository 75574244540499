@keyframes blinking {
    0% {color: white;}
    50% {color: aqua;}
    100% {color: white;}
}

.flex{
display:flex;
}
.sb{
    justify-content: space-evenly;
}
.br{
    position: relative;
    top:20%;
    right:20px;
}
.link{
    display:block;
    position: relative;
    color: aliceblue;
    top:25%;
    right:20px;
    float:right;
    font-size: 18px;
    font-family: Montserrat;
}
.link:hover{
    color:aliceblue;
}
.blink{
    background-color: blue;
    border: 2px solid aqua;
    border-radius: 80px;
    animation-name: blinking;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}
.inactive{
    background-color:transparent;
}
.active{
    /* background: url("/src/dragon-scales.png"); */
    background: rgb(137,0,164);
    background: linear-gradient(180deg, rgba(137,0,164,1) 0%, rgba(21,51,85,1) 100%);
}
@media screen and (max-width:800px) {
    .link{
        display:none
    }
}