.icon{
    height:20px;
    width:auto;
    cursor:pointer;
}
.pl{
    padding-left: 5px;
}
.name{
    display: none ;
    font-size: 18px;
    margin-right: 10px;
    background-color: transparent;
}
.name:hover{
    display: inline-block;
}